import { html, LitElement, TemplateResult } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, property } from 'lit/decorators.js';
import { onUserChanged, signOut, signIn } from '@seft/auth-service';
import type { User } from '@seft/auth-service/types';
import { MenuDirection } from '@seft/avatar/types';
import darkLogo from '@seft/design-tokens/assets/images/seft-letterform-dark.webp';
import lightLogo from '@seft/design-tokens/assets/images/seft-letterform.webp';
import styles from './styles';

import '@seft/avatar';
import '@seft/button';
import '@seft/menu/menu-item';

async function signInAction() {
  try {
    await signIn();
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert(e);
  }
}
/**
 * Renders the user's avatar via <seft-avatar> with the appropriate menu options.
 * @param user
 * @returns
 */
function renderAvatar(user: User): TemplateResult {
  return html`
    <seft-avatar id="avatar" .user=${user} menuDirection=${MenuDirection.Left}>
      <seft-menu-item slot="menu-item" @click=${() => signOut()}><span>Sign Out</span></seft-menu-item>
    </seft-avatar>
  `;
}
/**
 * Renders the Sign In button for users that have not yet signed in.
 * @returns
 */
function renderSignIn(): TemplateResult {
  return html`<seft-button @click=${signInAction}>Sign In</seft-button>`;
}

/**
 * Creates a HeaderElement for high level application navigational purposes.
 * @since 0.1.0-0
 * @status unstable
 * @tag seft-header
 */
@customElement('seft-header')
export class HeaderElement extends LitElement {
  static override styles = styles;

  static override shadowRootOptions: ShadowRootInit = { mode: 'open', delegatesFocus: true };

  private userChangeListener: (() => void) | null = null;

  @property({ type: Boolean }) hideSignIn?: boolean;

  @property({ type: Object }) user?: User | null;

  override connectedCallback(): void {
    super.connectedCallback();

    if (this.user === undefined) {
      this.userChangeListener = onUserChanged((user) => {
        this.user = user;
      });
    }
  }

  override disconnectedCallback(): void {
    super.disconnectedCallback();
    this.userChangeListener?.();
  }

  /**
   * Renders the primary DOM for Header
   * @returns
   */
  protected override render(): TemplateResult {
    const { hideSignIn, user } = this;

    let avatar: TemplateResult | null = null;
    switch (user) {
      case null:
        avatar = renderSignIn();
        break;
      case undefined:
        avatar = null;
        break;
      default:
        avatar = renderAvatar(user);
    }
    const signInButton = hideSignIn ? null : html`<div id="signin">${avatar}</div>`;

    return html`
      <header part="header">
        <slot name="logo" part="logo">
          <a slot="logo" href="/" aria-label="Link to the Seft Technologies LLC home page">
            <picture>
              <source srcset=${lightLogo} media="(prefers-color-scheme: dark)" />
              <img alt="Seft Technologies LLC logo" src=${darkLogo} />
            </picture>
          </a>
        </slot>
        <div part="nav">
          <slot name="nav"></slot>
        </div>
        ${signInButton}
      </header>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'seft-header': HeaderElement;
  }
}

export default HeaderElement;
